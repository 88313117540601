import { Theme } from '@mui/material/styles';
import { SystemStyleObject } from '@mui/system/styleFunctionSx';

export function modalWrapper(theme: Theme): SystemStyleObject<Theme> {
  const { transitions, breakpoints, borders, functions, typography, palette } = theme;

  const { size } = typography;
  const { borderRadius } = borders;
  const { pxToRem, rgba } = functions;
  const { white } = palette;

  return {
    borderRadius: borderRadius.xl,
    position: 'absolute',
    backgroundColor: rgba(white.main, 0.6),
    backdropFilter: `saturate(200%) blur(${pxToRem(64)})`,
    border: `1px solid ${white.main}`,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: '40vw',
    p: `${pxToRem(32)}`,
    '&:focus-visible': {
      outline: 'none'
    },

    [breakpoints.down('sm')]: {
      maxWidth: '95vw',
      fontSize: '3vw',
      '& p': {
        fontSize: size.xxs
      }
    },

    [breakpoints.up('xl')]: {
      transition: transitions.create(['box-shadow', 'background-color'], {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.shorter
      })
    }
  };
}

export const otpInputStyle = (
  theme: Theme,
  type: 'input' | 'focus' | 'error'
): SystemStyleObject<Theme> => {
  const { palette } = theme;

  switch (type) {
    case 'input':
      return {
        width: '2.3em',
        height: '2.3em',
        background: 'transparent',
        border: '1px solid #3336',
        borderRadius: '10px'
      };

    case 'focus':
      return {
        outline: `${palette.primary.focus} solid 1px`
      };

    case 'error':
      return {
        outline: `${palette.error.main} solid 2px`
      };

    default:
      return {};
  }
};
