// import { FormEvent, useEffect, useState } from 'react';
// import Link from 'next/link';
// import Card from '@mui/material/Card';
// import MDBox from 'components/MDBox';
// import MDTypography from 'components/MDTypography';
// import MDInput from 'components/MDInput';
// import MDButton from 'components/MDButton';
// import BasicLayout from 'domains/authentication/components/BasicLayout';
// import sidenavLogoLabel from 'examples/Sidenav/styles/sidenav';
// import PBBLogo from 'assets/svg/PBBLogo';
// import { useRouter } from 'next/router';
// import { useStore } from 'store';
// import { observer } from 'mobx-react-lite';
// import IconButton from '@mui/material/IconButton';
// import Visibility from '@mui/icons-material/Visibility';
// import VisibilityOff from '@mui/icons-material/VisibilityOff';
// import TokenVerificationModal from './TokenVerificationModal';

// function Basic() {
//   const [showPassword, setShowPassword] = useState(false);
//   const {
//     AuthStore: { isLoading, login, errors, isAuthenticated, Auth, authStage, setAuthStage }
//   } = useStore();
//   const route = useRouter();
//   const [credential, setCredential] = useState<{ email: string; password: string }>({
//     email: '',
//     password: ''
//   });

//   useEffect(() => {
//     const fromPath = localStorage.getItem('fromPath');
//     route.prefetch(fromPath || '/dashboard');
//   }, [route]);

//   useEffect(() => {
//     if (isAuthenticated()) {
//       const fromPath = localStorage.getItem('fromPath');
//       if (Auth.tenant)
//         route.replace(fromPath || '/dashboard').then(() => localStorage.removeItem('fromPath'));
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [isAuthenticated, Auth.tenant]);

//   const handleSubmit = (event: FormEvent<HTMLDivElement>) => {
//     event.preventDefault();
//     event.persist();
//     const formData = new FormData(event.target as any);
//     const [email, password] = [formData.get('email') as string, formData.get('password') as string];
//     setCredential({ email, password });
//     login({ password, email });
//   };

//   const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
//     event.preventDefault();
//   };

//   return (
//     <BasicLayout>
//       <Card
//         sx={{
//           bgcolor: '#fff',
//           '& label': {
//             color: '#7b809a',
//             fontWeight: 800
//           },
//           '& input': {
//             color: '#343a40'
//           }
//         }}
//       >
//         <MDBox borderRadius="lg" mx={2} p={2} mb={1}>
//           <MDBox sx={(theme) => sidenavLogoLabel(theme, { miniSidenav: false }, '#0fdba880')}>
//             <PBBLogo />
//           </MDBox>
//         </MDBox>
//         <MDBox pt={4} pb={3} px={3}>
//           <MDBox component="form" role="form" onSubmit={handleSubmit}>
//             {Boolean(errors.login) && (
//               <MDTypography variant="body2" color="error" textAlign="center" mb="2rem">
//                 {errors.login}
//               </MDTypography>
//             )}
//             <MDBox mb={4}>
//               <MDInput name="email" type="text" label="Email" fullWidth />
//             </MDBox>
//             <MDBox mb={4}>
//               <MDInput
//                 name="password"
//                 type={showPassword ? 'text' : 'password'}
//                 label="Password"
//                 fullWidth
//                 InputProps={{
//                   endAdornment: (
//                     <IconButton
//                       aria-label="toggle password visibility"
//                       onClick={() => setShowPassword((prev) => !prev)}
//                       onMouseDown={handleMouseDownPassword}
//                       edge="end"
//                     >
//                       {showPassword ? (
//                         <VisibilityOff titleAccess="hide password" />
//                       ) : (
//                         <Visibility titleAccess="show password" />
//                       )}
//                     </IconButton>
//                   )
//                 }}
//               />
//             </MDBox>
//             <MDBox mt={4} mb={1}>
//               <MDButton variant="gradient" color="primary" fullWidth type="submit">
//                 {isLoading.login ? 'Loading...' : 'Sign in'}
//               </MDButton>
//             </MDBox>
//             <MDBox mt={3} mb={1} textAlign="center">
//               <MDTypography variant="button" color="text" sx={{ userSelect: 'none' }}>
//                 Forgot password?{' '}
//                 <Link href="?#" passHref>
//                   <MDTypography
//                     variant="button"
//                     color="error"
//                     fontWeight="medium"
//                     textGradient
//                     sx={{ cursor: 'pointer' }}
//                   >
//                     Reset Password
//                   </MDTypography>
//                 </Link>
//               </MDTypography>
//             </MDBox>
//           </MDBox>
//         </MDBox>
//       </Card>
//       {authStage === 'verification' && (
//         <TokenVerificationModal
//           open={authStage === 'verification'}
//           onClose={(e, reason) =>
//             reason !== 'continue' ? setAuthStage('credential') : route.replace('/dashboard')
//           }
//           {...credential}
//         />
//       )}
//     </BasicLayout>
//   );
// }

// export default observer(Basic);

import { Slide } from '@mui/material';
import MDTypography from 'components/MDTypography';
import { Page } from 'constants/route';
import { useStore } from 'store';
import React, { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { observer } from 'mobx-react-lite';
import { EAuthStage } from 'store/AuthStore';
import MDBox from 'components/MDBox';
import useAlert from 'utils/Alert';
import MDButton from 'components/MDButton';
import { requestResetPassword } from 'requests/auth/password';
import EmailChecker from './emailChecker';
import PasswordChallenge from './passwordChallenge';
import Verify from '../Verify';
import InitAccount from './initAccount';

const Login = () => {
  const route = useRouter();
  const { e } = route.query;
  const {
    AuthStore: {
      authStage,
      cardSlideDir,
      currentEmailKey,
      tempEmailObj,
      setAuthStage,
      signinEmail,
      resetingPassword,
      setResetingPassword
    }
  } = useStore();
  const [email, setEmail] = useState('');
  const Alert = useAlert();

  useEffect(() => {
    route.prefetch(Page.dashboard);
  }, [route]);

  useEffect(() => {
    if (authStage === EAuthStage.AUTHENTICATED) {
      route.replace(Page.dashboard);
    }
  }, [authStage, route]);

  useEffect(() => {
    if (currentEmailKey) {
      route.replace(Page.Auth.login.concat(`?e=${currentEmailKey}`), undefined, {
        shallow: true
      });
    }
  }, [currentEmailKey, route]);

  useEffect(() => {
    if (e && e !== currentEmailKey) {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const email = tempEmailObj[e as string];
      if (
        email &&
        ![
          EAuthStage.AUTHENTICATED,
          EAuthStage.PASSWORD_CHALLENGE,
          EAuthStage.TWO_FA_CHALLENGE
        ].includes(authStage)
      ) {
        setEmail(email);
        setAuthStage(EAuthStage.PASSWORD_CHALLENGE);
      }
    }
  }, [tempEmailObj, route, e, currentEmailKey, authStage, setAuthStage]);

  const slideKey = useMemo(() => {
    switch (authStage) {
      case EAuthStage.IDLE:
      case EAuthStage.CHECK_EMAIL:
        return 'email';
      case EAuthStage.PASSWORD_CHALLENGE:
        return 'password';
      case EAuthStage.INITIALIZING_ACCOUNT:
        return 'init';
      case EAuthStage.TWO_FA_CHALLENGE:
      case EAuthStage.AUTHENTICATED:
        return 'verify';
      default:
        return 'email';
    }
  }, [authStage]);

  const handleResetPassword = () => {
    if (authStage === EAuthStage.PASSWORD_CHALLENGE) {
      setResetingPassword(true);
      requestResetPassword(email || signinEmail)
        .then(() => {
          Alert.success('Password reset link sent. please check your email.');
        })
        .catch((err) => {
          Alert.error(err.response?.data?.message || err.message);
        })
        .finally(() => setResetingPassword(false));
    }
  };

  return (
    <>
      <Slide in key={slideKey} unmountOnExit direction={cardSlideDir}>
        <MDBox pb={3} px={3} width="100%">
          {[EAuthStage.IDLE, EAuthStage.CHECK_EMAIL].includes(authStage) && <EmailChecker />}
          {authStage === EAuthStage.PASSWORD_CHALLENGE && (
            <PasswordChallenge email={email || signinEmail} />
          )}
          {authStage === EAuthStage.INITIALIZING_ACCOUNT && <InitAccount />}
          {[EAuthStage.TWO_FA_CHALLENGE, EAuthStage.AUTHENTICATED].includes(authStage) && (
            <Verify {...{ email: email || signinEmail }} />
          )}
        </MDBox>
      </Slide>
      {[EAuthStage.IDLE, EAuthStage.CHECK_EMAIL, EAuthStage.PASSWORD_CHALLENGE].includes(
        authStage
      ) && (
        <>
          <MDBox mt={3} mb={1} textAlign="center">
            <MDTypography variant="button" color="text" sx={{ userSelect: 'none' }}>
              Forgot password?&nbsp;
              <MDButton
                variant="text"
                color="error"
                form="check-email-form"
                type="submit"
                id="reset-password"
                disabled={resetingPassword}
                onClick={handleResetPassword}
              >
                {resetingPassword ? 'Processing...' : 'Reset Password'}
              </MDButton>
            </MDTypography>
          </MDBox>
        </>
      )}
    </>
  );
};

export default observer(Login);
